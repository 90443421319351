import * as React from 'react'

import Layout from '../layouts'

import './index.scss'

const IndexPage = ({ data }) => {
  const missingSellableIngredient = data.allContentfulRecipe.nodes.map((node) => node.ingredients).flat().
    filter((ingredient) => {
      return ingredient.ingredient == null && !ingredient.title.match(/salt/i) && !ingredient.title.match(/water/i)
    })

  const missingAmount = data.allContentfulRecipe.nodes.map((node) => node.ingredients).flat().
    filter((ingredient) => {
      return ingredient.amount == null
    })

  return (
    <Layout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Missing a Sellable Ingredient</h2>
          </div>
          {missingSellableIngredient.map((ingredient) => (
            <div className="col-3">
              { ingredient.title}
            </div>
          ))}
          <div className="col-12">
            <h2>Missing an amount</h2>
          </div>
          {missingAmount.map((ingredient) => (
            <div className="col-3 h-250px">
              <div>{ingredient.title}</div>
              <img className="img-fluid mh-75p mx-auto my-auto" src={ingredient.ingredient?.picture?.fluid?.src} />
            </div>
          ))}
        </div>
      </div>
    </Layout >
  )
}

export const query = graphql`
query {
  allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
    nodes {
      ingredients {
        amount
        title
        ingredient {
          id
          name
          sku
          priceCents
          section
          brand
          quantityDescription
          finalized
          contentful_id
          picture {
            file {
              url
            }
            fluid(quality: 100, maxWidth:150) {
              src
              base64
            }
          }
        }
      }
    }
  }
}

`

export default IndexPage
